* {
    font-family: 'Roboto', sans-serif;
}

.filter-bar {
    background: rgb(233, 229, 229);
}

.menu-link {
    text-decoration: none !important;
    color: #000 !important;
}

.hyperlink {
    cursor: pointer;
    color: blue;
}

.loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.MuiOutlinedInput-root div,
.bg-white {
    background-color: white !important;
}

.graph-tooltip {
    position: 'relative';
    border-radius: '6px';
    padding: '4px 12px';
    background-color: '#6dd1e7';
    color: '#fff';
}

.MuiDataGrid-filterFormValueInput div .MuiFormControl-root .MuiOutlinedInput-root {
    height: 3em;
}

.MuiDataGrid-filterFormValueInput div div fieldset,
.MuiDataGrid-filterFormValueInput div div fieldset:hover {
    border-right: none;
    border-top: none;
    border-radius: 0px;
    border-left: none;
    border-bottom: 2.5px solid #1976d2;
}

.MuiDataGrid-filterFormValueInput div div {
    padding-bottom: 0px;
}

.MuiDataGrid-filterFormValueInput div div label {
    margin-top: 4%;
}

.MuiDataGrid-filterFormValueInput div div div input {
    padding-bottom: 0px;
}

.MuiDataGrid-filterFormValueInput div div div div button {
    margin-bottom: -50%;
}

.only-print-content {
    display: none;
}

.css-1oqqzyl-MuiContainer-root {
    padding-left: 0px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0px 4px 0px 0px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding-left: 0px !important;
    padding-bottom: 0px;
}

.MuiDataGrid-virtualScroller {
    padding: 0px !important;
    scrollbar-width: thin !important;
    border-bottom-width: 0px !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
    height: 6px !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.534);
    background-color: #222c62;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
    background-color: rgba(255, 254, 254, 0.644);
    border-radius: 0.3em;
}

.MuiDialogContent-root::-webkit-scrollbar {
    width: 6px !important;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.534);
    background-color: #222c62;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
    background-color: rgba(255, 254, 254, 0.644);
    border-radius: 0.3em;
}

.MuiPaper-root::-webkit-scrollbar {
    width: 6px !important;
}

.MuiPaper-root::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.534);
    background-color: #222c62;
}

.MuiPaper-root::-webkit-scrollbar-thumb {
    background-color: rgba(255, 254, 254, 0.644);
    border-radius: 0.3em;
}

.MuiDataGrid-panelContent::-webkit-scrollbar {
    width: 6px !important;
}

.MuiDataGrid-panelContent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.534);
    background-color: #222c62;
}

.MuiDataGrid-panelContent::-webkit-scrollbar-thumb {
    background-color: rgba(255, 254, 254, 0.644);
    border-radius: 0.3em;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: 300 !important;
}

.MuiDataGrid-cell {
    border-bottom-width: 0px !important;
}

.MuiDataGrid-footerContainer {
    border-top: none !important;
}

.css-855x0u-MuiPaper-root {
    box-shadow: none !important;
}

.custom-cell {
    cursor: auto !important;
}

.MuiTablePagination-actions button {
    color: rgba(255, 255, 255, 0.678) !important;
}

.css-1ps6pg7-MuiPaper-root ul li {
    font-weight: 300;
    font-size: 12px;
    border-bottom: 1px solid #ffffff67;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    padding-bottom: 0px !important;
}

body,
html,
#wrapper {
    width: 100% !important;
}

@media print {
    body {
        border: 1px solid white;
        width: 100% !important;
        height: 100%;
    }

    .recharts-wrapper, .recharts-surface, .recharts-legend-wrapper {
        width: 100%!important;
    }

    .recharts-legend-item > svg{
        width: auto!important;
        display: inline-block!important;
        right:inherit!important; /* align to left */
    }

    @page {
        size: A4;
        margin: 10px;
    }

    .no-print {
        display: none !important;
    }

    .only-print-content {
        display: block;
        background-color: white;
    }

    .MuiTypography-root {
        text-align: center !important;
        color: #000 !important;
        margin-top: 10px !important;
    }

    .print-overflow {
        overflow: unset !important;
    }

    .MuiGrid-container-print {
        display: block !important;
        break-inside: avoid !important;
    }

    .report-header-container {
        border: 0.1px solid #e7e7e7;
        padding-left: 8px;
        margin: 1px;
        box-shadow: 1px 1px 1px #80808061;
        margin-bottom: 5px;
    }

    .print-pd0 {
        padding: 0 !important;
    }

    .title-center {
        text-align: center;
    }

    .highlight {
        border: 4px solid yellow;
    }

    .recharts-text,
    .recharts-label,
    .recharts-wrapper,
    .recharts-legend-wrapper {
        fill: #000 !important;
        color: #000 !important;
    }

    .recharts-wrapper,
    .recharts-surface {
        background-color: #000 !important;
    }

    .MuiDataGrid-columnHeaderTitle,
    .MuiDataGrid-cellContent {
        color: #000 !important;
    }

    .print-flex {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
    }

    .print-show {
        color: #000 !important;
    }

    .makeStyles-header-8,
    .makeStyles-header-16,
    .makeStyles-header-24 {
        border-bottom: 2px solid #000;
    }

    .st0 {
        fill: none !important;
    }

    .st1 {
        fill: #007bc3 !important;
    }

    .st2 {
        fill: #142840 !important;
    }
}

@media (min-width: 1200px) {
    .css-1f2kw6w-MuiContainer-root {
        max-width: 1800px !important;
    }

    .MuiContainer-root {
        max-width: 1800px !important;
    }
}

@media (min-width: 1536px) {
    .css-7rldf8-MuiContainer-root {
        max-width: 1744px !important;
    }

    .MuiContainer-root {
        max-width: 1744px !important;
    }
}

.MuiDataGrid-cell {
    white-space: pre !important;
}

.MuiDataGrid-cellContent {
    white-space: break-spaces !important;
}

#root{
    position: fixed;
    width: 100%;
}
